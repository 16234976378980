import '../styles/templates/case-study.scss'

import { Link } from 'gatsby'
import React from 'react'
import { BsArrowRight } from 'react-icons/bs'

import { PAGE_NAME } from '../../config/cms'
import { Carousel } from '../components/carousel'
import { CaseStudiesList } from '../components/case-studies-list'
import { ArrowIndicator } from '../components/icons/arrow-indicator'
import { Layout } from '../components/layout'
import { PageHeading } from '../components/page-heading'
import { PageSubheading } from '../components/page-subheading'
import { Screen } from '../config/screens'
import type { CaseStudy } from '../hooks/cms/use-case-studies'
import { useEntity } from '../hooks/cms/use-entity'
import { usePageData } from '../hooks/cms/use-page'
import { useProducts } from '../hooks/cms/use-products'
import { useSoftware } from '../hooks/cms/use-software'
import { useHrefMaker } from '../hooks/use-href-maker'
import { useScreen } from '../hooks/use-screen'
import { PageComponentFactory } from '../utils/pages'

const TEMPLATE_NAME = PAGE_NAME.CaseStudy

const getFilePath = (file: string) =>
  file ? `/assets/files/${file.split('/').pop()}` : ''

const CaseStudyPage = PageComponentFactory(() => {
  const caseStudy = useEntity<CaseStudy>()
  const products = useProducts()
  const software = useSoftware()
  const isLarge = useScreen(Screen.Large)
  const makeHref = useHrefMaker()

  const filteredProducts = products.list.filter(({ identifier }) =>
    (caseStudy.products ?? []).includes(identifier)
  )
  const filteredSoftware = software.list.filter(({ identifier }) =>
    (caseStudy.software ?? []).includes(identifier)
  )

  const { landingSection } = usePageData(PAGE_NAME.CaseStudy)
  const { strings } = usePageData(PAGE_NAME.CaseStudies)

  const isProduct = (id: string): boolean =>
    (caseStudy.products ?? []).includes(id)

  return (
    <Layout
      page={TEMPLATE_NAME}
      variant="primary"
      pathname={`/case-studies/${caseStudy.identifier}`}
    >
      <main className="case-study">
        <section className="case-study__landing-section">
          <div className="container">
            <div className="case-study__grid-container">
              <div className="case-study-page__content">
                <PageHeading>{caseStudy.summary.title}</PageHeading>
                <PageSubheading>{caseStudy.summary.description}</PageSubheading>
                <ArrowIndicator />
              </div>
              <section className="case-study__products-list">
                <div>
                  <span>{landingSection.products}</span>
                  {[...filteredProducts, ...filteredSoftware].map(product => {
                    const isSoftware = filteredSoftware.some(
                      software => software.identifier === product.identifier
                    )
                    return (
                      <>
                        <Link
                          key={product.identifier}
                          className="list-element"
                          to={makeHref(
                            isSoftware
                              ? `/software/${product.identifier}`
                              : `/products/${product.identifier}`
                          )}
                        >
                          {product.name}
                        </Link>
                        <span className="divider-line" />
                      </>
                    )
                  })}
                </div>
                <div className="case-study__products-list-location">
                  <span>{landingSection.location}</span>
                  <span> {caseStudy.summary.location}</span>
                </div>
              </section>
            </div>
          </div>
        </section>

        <section className="case-study-description section">
          <h3 className="case-study-description__header">
            {caseStudy.summary.descriptionAlt}
          </h3>
          <pre className="case-study-description__article">
            <h4>
              {caseStudy.article}
              <p>
                <a href={caseStudy.ytLink}>
                  <u>{caseStudy.summary.linkText}</u>
                </a>
              </p>
            </h4>
          </pre>
          <div className="case-study-description__products">
            {[...filteredProducts, ...filteredSoftware].map((data, index) => {
              const { name, heading, identifier } = data

              const image =
                data.media && 'previewImage' in data.media
                  ? data.media.previewImage
                  : 'preview' in data
                  ? data.preview?.image
                  : null

              return (
                <Link
                  to={makeHref(
                    `/${
                      isProduct(identifier) ? 'products' : 'software'
                    }/${identifier}`
                  )}
                  className="case-study-description__products-element"
                  key={index}
                >
                  <div className="case-study-description__products-element-image">
                    <img
                      src={image ? getFilePath(image as string) : undefined}
                    />
                  </div>
                  <div className="case-study-description__products-element-text">
                    <h4> {name}</h4>
                    <h4> {heading}</h4>
                  </div>
                  <div className="case-study-description__products-element-icon">
                    <BsArrowRight />
                  </div>
                </Link>
              )
            })}
          </div>
        </section>
        <section className="case-study-carousel section">
          {(caseStudy.media?.items ?? []).length && (
            <Carousel
              visibleSlides={isLarge ? 2 : 1}
              slides={(caseStudy.media?.items ?? []).map(({ image }, key) => (
                <img
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%',
                  }}
                  key={key}
                  src={getFilePath(image)}
                />
              ))}
            />
          )}
        </section>
        <section className="case-study__highlights section">
          {caseStudy.highlights.map(({ top, bottom }, index) => (
            <div className="case-study__highlights-element" key={index}>
              <h1>{top}</h1>
              <h3>{bottom}</h3>
            </div>
          ))}
        </section>
        <section className="case-study__case-study section">
          <CaseStudiesList
            strings={strings}
            filtersEnabled={false}
            excludedCaseStudyId={caseStudy.identifier}
            max={5}
          />
        </section>
      </main>
    </Layout>
  )
})

export default CaseStudyPage
